import React from "react"
import "../styles/index.scss"



const Video = ({ videoSrcURL, videoTitle, videoWidth, videoHeight, ...props }) => (
    <div className="embed-responsive embed-responsive-4by3">
    <iframe className="embed-responsive-item" style ={{"paddingTop": "80px", "merginRight": "40px" }}

        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        // width = {videoWidth}
        // height = {videoHeight}
      />
    </div>
  )

export default Video
