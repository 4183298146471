import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioFold from "../components/portfolio-fold.js"



const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Devashish`, `Mulye`, `portfolio`,`showcase`]} />
    <PortfolioFold title='Sketches' description='I have made some sketches that cross-over from silly to rivetting. Mostly silly. I like making up characters. Here is my favorite one.' videoURL="https://www.youtube.com/embed/VYplGc0IxAE"></PortfolioFold>
    <PortfolioFold title = 'Street Interviews' description='Here is a video of me interviewing people about Kshitij, the annual techno-management fest of IIT Kharagpur' videoURL="https://www.youtube.com/embed/FLnxpEUwVN8"></PortfolioFold>
    <PortfolioFold title = 'The Social Animal (Short Film)' description='This short film is an outcome of a phase of life in which I had a diet of exclusively absurdist movies' videoURL="https://www.youtube.com/embed/4RojtSktZBA"></PortfolioFold>
    <PortfolioFold title = 'Tyred (Short Film)' description='I made this really cute short film about a day in the life of a bicyle (in the middle of an existential conundrum)' videoURL="https://www.youtube.com/embed/X5QPseakBSU"></PortfolioFold>

  </Layout>
)

export default IndexPage
