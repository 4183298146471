import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Video from "./video.js"



const PortfolioFold = ({title,videoURL,description}) => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery2 {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className='foldContainer' >
          <div className='innerFoldContainer'>
            <p className="nameHeader" style={{"paddingTop": "7rem"}}> {title} </p>
            <p className='description descriptionFont'> {description} </p>
          </div>
          <div className='innerfoldVideoContainer'>
          <Video style={{"marginTop": "10rem"}}
            videoSrcURL={videoURL}
            videoTitle="Official Music Video on YouTube"
            videoWidth="560"
            videoHeight="395"
          />
          </div>
      </div>


    )}
  />
)

export default PortfolioFold
